import React from 'react';
import { Link } from 'gatsby';
import styles from '../components/styles/company.module.css';

export const CompanyProfileContent = () => {
  const COMPANY_PROFILES = [
    {
      title: '商号',
      content: (
        <>
          <p>バークリー・ジャパン株式会社</p>
          <p>【VarclyJapan Co., Ltd.】</p>
        </>
      ),
    },
    { title: '設立', content: <p>2019年1月23日</p> },
    {
      title: '所在地',
      content: (
        <>
          <p>〒160-0022</p>
          <p>東京都新宿区新宿5丁目2番3号　MRCビル7F</p>
          <Link to='/access/'>
            <p className={styles.toAccessPage}>ACCESSページはこちら ▶︎</p>
          </Link>
        </>
      ),
    },
    {
      title: '連絡先',
      content: (
        <>
          <p>TEL：03-5363-2090</p>
          <p>FAX：03-5363-2091</p>
        </>
      ),
    },
    { title: 'URL', content: <p>https://www.varcly.co.jp</p> },
    { title: '代表', content: <p>代表取締役　井上 将之</p> },
    { title: '資本金', content: <p>20,000千円</p> },
    {
      title: '主要株主',
      content: (
        <>
          <a
            href='https://www.nisso-ind.co.jp/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <span className={styles.NissoName}>日創工業株式会社</span>
          </a>
          <span>（100%出資）</span>
        </>
      ),
    },
    {
      title: '取引銀行',
      content: (
        <>
          <p>三井住友銀行　渋谷駅前支店</p>
          <p>東日本銀行　　渋谷支店</p>
          <p>りそな銀行　　新宿支店</p>
        </>
      ),
    },
    {
      title: '主要業務内容',
      content: (
        <>
          <p>Webサービスにかかわるシステムコンサルティング事業</p>
          <p>Webシステム開発事業</p>
          <p>ITインフラ基盤設計構築事業</p>
        </>
      ),
    },
  ];

  return (
    <>
      {COMPANY_PROFILES.map((companyProfile, i) => {
        return (
          <div className={styles.companyProfile} key={`company-profile-${i}`}>
            <p className={styles.companyProfileKey}>{companyProfile.title}</p>
            <div className={styles.companyProfileValue}>
              {companyProfile.content}
            </div>
          </div>
        );
      })}
    </>
  );
};
