import React from 'react';
import styles from '../components/styles/company.module.css';

export const DevelopmentSloganContent = () => {
  const headline1 = <p>私たちは最適なユーザビリティを追求します</p>;
  const headline2 = <p>私たちは品質に妥協しません</p>;
  const headline3 = <p>私たちはお客様の求めているシステムを創造します</p>;

  const firstDescription = (
    <p className={styles.developmentSloganDescription}>
      システムの利用者は、お客様自身であったり、お客様のお客様であったり、案件によりさまざまです。
      どのような場合でも、誰がシステムを利用するのかを念頭に置いてシステム開発に取り組みます。
      プロトタイプの構築や利用者へのヒアリングといった手法を用い、利用者目線でのユーザビリティを追求いたします。
    </p>
  );
  const secondDescription = (
    <p className={styles.developmentSloganDescription}>
      私たちはご予算と期限の許す限り、常に最適な品質のシステムを提案いたします。
      仮に低予算だったとしても、低品質なシステムでお茶を濁すことはいたしません。
      常に品質を追求することで、お客様のシステム投資に寄与するのみでなく、弊社社員の技術力向上にも繋がっていくと考えています。
      セキュリティ品質に関しても、大手セキュリティ診断会社の診断をクリアする水準のシステムを提供いたします。
    </p>
  );

  const thirdDescription = (
    <p className={styles.developmentSloganDescription}>
      思い切って注文したシステムが、完成してみたら思っていたものと違った。不便だが仕方なく利用している。
      システム投資のよくある失敗例だと思います。
      <br />
      弊社ではお客様との打ち合わせからシステム構築まで、社内のエンジニアがワンストップで実施しています。
      お客様のご要望を伺ったエンジニア本人が設計やコーディングにも関わることで、お客様が真に求めているシステムを提供いたします。
    </p>
  );
  return (
    <>
      <h2 className={styles.headline}>{headline1}</h2>
      {firstDescription}
      <h2 className={styles.headline}>{headline2}</h2>
      {secondDescription}
      <h2 className={styles.headline}>{headline3}</h2>
      {thirdDescription}
    </>
  );
};
