import React from 'react';
import styles from '../components/styles/company.module.css';

export const ManagementPhilosophyContent = () => {
  const headline = <p>社員至上主義</p>;

  const managementPhilosophy = (
    <p>
      まずは当社で働く社員のストレスを無くし環境をより良くする事。
      <br />
      これが実現できて初めてお客様に対して信頼度の高い価値あるサービスが実現できると考えています。
      <br />
      昨今、IT業界はブラックだ。などとまことしやかに噂されています。
      確かに薄給激務なエンジニアが存在していることも事実であり、日々決められた通りの業務をこなしていくだけのエンジニアがいることも事実です。
      そんなIT業界に一石を投じたい。そんな思いでこのPhilosophyを掲げました。
      <br />
      ただ、誤解してほしくないのは社員の我がままを通すことではございません。
      社員が成長していくための環境や待遇を会社が用意する。だからこそ社員一人一人が自身の価値を向上するために行動する。
      そんな好循環を生み出し、企業の価値を向上させてまいります。
    </p>
  );
  return (
    <>
      <h2 className={styles.headline}>{headline}</h2>
      {managementPhilosophy}
    </>
  );
};
