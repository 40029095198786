import React from 'react';
import styles from '../components/styles/company.module.css';

export const CompanyVisionContent = () => {
  const headline = <p>ストレスフリーの社会に</p>;

  const description = (
    <p>
      昨今、IT業界の進歩は目まぐるしく変化し、様々なソフトウェアやサービスが提供されています。
      <br />
      新たな価値のあるサービスを創生・提供することで、日々の生活や働き方に変化をもたらし、ストレスを軽減することで、より新たな価値や時間を創出して社会に貢献してまいります。
    </p>
  );
  return (
    <>
      <h2 className={styles.headline}>{headline}</h2>
      {description}
    </>
  );
};
