import React, { useEffect, useRef } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { HeaderImage } from '../components/HeaderImage.jsx';
import { CompanyVisionContent } from '../components/CompanyVision.jsx';
import { ManagementPhilosophyContent } from '../components/ManagementPhilosophy.jsx';
import { MessageFromCEOContent } from '../components/MessageFromCEO.jsx';
import { CompanyProfileContent } from '../components/CompanyProfile.jsx';
import { DevelopmentSloganContent } from '../components/DevelopmentSloganContent.jsx';
import styles from '../components/styles/company.module.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Company = () => {
  const refs = useRef([]);
  refs.current = [];

  useEffect(() => {
    refs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0, y: +60 },
        {
          duration: 0.8,
          autoAlpha: 1,
          y: 0,
          ease: 'power4.out',
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: 'top bottom-=80',
            end: 'bottom 100',
            toggleActions: 'play pause complete reverse',
          },
        }
      );
    });
  });

  const companyContents = [
    { title: 'VISION', subTitle: '企業理念', content: CompanyVisionContent() },
    {
      title: 'PHILOSOPHY',
      subTitle: '経営理念',
      content: ManagementPhilosophyContent(),
    },
    {
      title: 'SLOGAN',
      subTitle: 'スローガン',
      content: DevelopmentSloganContent(),
    },
    {
      title: 'MESSAGE',
      subTitle: '代表メッセージ',
      content: MessageFromCEOContent(),
    },
    {
      title: 'PROFILE',
      subTitle: '会社概要',
      content: CompanyProfileContent(),
    },
  ];

  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  return (
    <Layout>
      <SEO title='会社紹介' description='バークリージャパンは新たな価値のあるサービスを創生・提供することで、日々の生活や働き方に変化をもたらし、ストレスを軽減することで、より新たな価値や時間を創出して社会に貢献してまいります。'/>
      <HeaderImage
        title='COMPANY'
        subTitle='会社紹介'
        fileName='company.jpg'
        icon='company-icon.png'
      />
      <div className={styles.companyPageContent}>
        {companyContents.map(({ title, subTitle, content }, i) => (
          <section className={styles.section} key={`company-content-${i}`}>
            <h1 className={styles.sectionTitle} ref={addToRefs}>
              {title}
            </h1>
            <h2 className={styles.subTitle} ref={addToRefs}>
              {subTitle}
            </h2>
            <section className={styles.sectionContent} ref={addToRefs}>
              {content}
            </section>
          </section>
        ))}
      </div>
    </Layout>
  );
};

export default Company;
