import React from 'react';
import styles from '../components/styles/company.module.css';
import picCEO from '../images/ceo-picture.jpg';

export const MessageFromCEOContent = () => {
  const message = (
    <p className={styles.message}>
      バークリー・ジャパン株式会社は、40年以上にわたりIT業界において事業を展開している日創工業株式会社100%出資による子会社として、2019年4月1日に事業をスタートいたしました。
      今日に至るまで関係各位の絶大なるご支援とご協力を賜りましたことに対し、ここに謹んでお礼申し上げます。
      <br />
      日本は今日に至るまであらゆる製品を生み出し、その高品質なモノづくりにおいて高度な経済成長を成しえてまいりました。
      <br />
      しかしながら、世界を見渡すとモノづくりの中心はIT技術の進化・発展により、無形のサービスへと変貌を遂げています。私たちはこの新たなサービスを自ら作り出し、「ストレスフリーの社会を」という企業理念のもと、次世代の技術やサービスを以て日本初のサービスを創っていきたいと考えています。
      新たに提供する利便性の高いサービスを軸に皆様の価値創生にお役立てしていく所存です。
      <br />
      今後とも、皆様のご理解とご支援を賜りますようお願い申し上げます。
    </p>
  );

  return (
    <>
      <div className={styles.picCEO}>
        <img src={picCEO} alt='' />
        <p>代表取締役社長　井上将之</p>
      </div>
      {message}
    </>
  );
};
